function contains(string, query) {
    return location.host.indexOf(query) >= 0
}

function insertFeed(selector, options) {
    if (options && (options.loaded || options.error)) {
		var callback = true;
	}
    
    var target = document.querySelector(selector),
        origin = contains(location.host, ".test") ? "http://auth.test" : "https://auth.cremadesignstudio.com";

    fetch(origin + "/feed", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(options)
    }).then(response => {
        return response.text();
    }).then(html => {
        target.insertAdjacentHTML('beforeend', html);

        if (callback && typeof options.loaded === 'function') {
			options.loaded.call(target, options);
		}
    }).catch(error => {
        if (callback && typeof options.error === 'function') {
			options.error.call(target, options);
		}
    });
}
